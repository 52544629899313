
.sidebar {
  position: sticky;
  height:95vh;
  flex: 0 1 400px; 
  max-width: 300px;
  padding: 20px; 
  color: #e6e6e6;
  box-sizing: border-box; 
  display: flex;
  flex-direction: column;
  align-items: center;
  top: -40px;
}

.sidebar-social-icons {
  display: flex;
  gap: 20px;
  margin-top: auto;
  margin-bottom: -30px; 
}


.sidebar-social-icons a i {
  color: #031c31;
  font-size: 3em; 
  transition: color 0.3s ease;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); 
}

.sidebar-social-icons a i:hover {
  color: #4d7fa8; 
}


.sidebar-header {
  display: flex;
  flex-direction: column; 
  align-items: center; 
  white-space: nowrap; 
}


.sidebar-links {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 2em;
  font-family: "Playfair Display", serif;
  font-weight: 500;
  position: relative;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);

}

.sidebar-links li {
  margin: 20px 0;
  height: 2em; 
  overflow: hidden; 
}


.sidebar-links a {
  text-decoration: none;
  color: #031c31; 
  font-size: 1.4em; 
  font-family: "Playfair Display", serif;
  font-weight: 500;
  position: relative; 
  display: inline-block; 
  transition: font-size 0.3s ease, color 0.3s ease, transform 0.2s ease;
  transform-origin: center;
}


.sidebar-links a:hover {
  font-size: 1.5em; 
  color: #4d7fa8; 
  z-index: 1;
}

.sidebar-links a:active {
  font-size: 1.4em; 
}



.sidebar-header img {
  width: 120%;
}




@media (max-width: 940px) {
  .sidebar {
    position: fixed;
    top: 0;
    z-index: 1000;
    padding: 0;
    margin: 0;
    flex-direction: row;
    width: 100%;
    max-width: 90%;
    height: auto;
  }

  .sidebar-header img {
    width: 60px;
    height: auto; 
    display: block;

  }

  .sidebar-links {
    position: relative; 
    display: flex; 
    flex-grow: 1; 
    justify-content: space-around;
    background-color: #c7c7c7;
    z-index: 999;
    list-style: none;
    flex-direction: row;
    font-size: 0.9rem;
    margin-bottom: 10px;
    }

  .sidebar-links li {
    display: inline-block;
    margin: 0;
    list-style: none;
  }

  .sidebar-links a {
    font-size: 1.2em;
    text-decoration: none;
    color: #031c31;
    border-radius: 5px;
    padding: 5px 10px;
    transition: background-color 0.3s ease, color 0.3s ease;
  }

  .sidebar-header h1 {
    font-size: 1.4em;
    padding-top: 5px;
  }


  .sidebar-header p {
    font-size: 0.8em;
    margin: 0;
  }

  .sidebar-social-icons {
    display: none;
  }

  .sidebar-links a:hover {
    font-size: 1.2em; 
    color: #4d7fa8;
    z-index: auto;
  }

  .sidebar-links a:active {
    font-size: 1.2em;
  }
}


@media (max-width: 480px) {
  .sidebar {
    position: fixed;
    top: 0;
    z-index: 1000;
    padding: 0;
    margin: 0;
    width: 100%;
    max-width: 100%;
    display: flex; 
    align-items: center; 
    background-color: #c7c7c7;
    flex-direction: row;
  }

  .sidebar-header {
    display: flex; 
    align-items: center; 
    justify-content: center;
    position: relative;
    text-align: center;

    padding: 0px 10px; 
    margin: 0;
    z-index: 1;
  }

  .sidebar-header img {
    width: 60px;
    height: auto; 
    display: block;

  }

  .sidebar-links {
    position: relative; 
    display: flex; 
    flex-grow: 1; 
    justify-content: space-around;
    background-color: #c7c7c7;
    z-index: 999;
    list-style: none;
    flex-direction: row;
    font-size: 0.9rem;
    margin-bottom: 10px;
    }

  .sidebar-links li {
    display: inline-block;
    margin: 0;
    list-style: none;
  }

  .sidebar-links a {
    font-size: 1.2em;
    text-decoration: none;
    color: #031c31;
    border-radius: 5px;
    padding: 5px 10px;
    transition: background-color 0.3s ease, color 0.3s ease;
  }

  .sidebar-header h1 {
    font-size: 1.4em;
    padding-top: 5px;
  }

  .sidebar-header h2 {
    font-size: 1.2em;
    margin-bottom: 5px;
  }

  .sidebar-header p {
    font-size: 0.8em;
    margin: 0;
  }

  .sidebar-social-icons {
    display: none;
  }

  .sidebar-links a:hover {
    font-size: 1.2em; 
    color: #4d7fa8;
    z-index: auto;
  }

  .sidebar-links a:active {
    font-size: 1.2em;
  }
}

  




