.about-me-section {
    height: auto; 
    margin: auto; 
    border-radius: 10px;
    text-align: center;
    max-width: 80%;
    box-sizing: border-box;
    transition: background-color 0.3s ease;
    margin-top: -70px;
}

.about-me-section h2 {
    font-size: 8.5em;
    margin-bottom: -10px;
    color: #031c31;
    font-family: "Playfair Display", serif;
    font-weight: 700;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
}

.about-me-section p {
    font-size: 1.2em;
    color: #031c31;
    line-height: 1.4;
    margin: 10px 0;
    font-family:monospace;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

.slideshow-container {
    margin: 30px auto; 
    position: relative;
    width: 100%; 
    max-width: 600px; 
    max-height: 500px;
    overflow: hidden;
    border-radius: 10px; 
    display: flex; 
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    z-index: 2;
}
.slideshow-image {
    width: 100%;
    height: 100%; 
    border-radius: 10px; 
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3); 
    object-fit: cover; 
    margin-top: -25px;
}

.about-me-social-icons {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: -10px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); 
  }
  
  .about-me-social-icons a i {
    color: #031c31;
    font-size: 3em;
    transition: color 0.3s ease;
  }
  
  .about-me-social-icons a i:hover {
    color: #4d7fa8;
  }  

img:active {
    transform: scale(1.05);
}

@media (max-width: 940px) {
    .about-me-section {
        max-width: 90%;

        margin-top: 0px;
    }

    .about-me-section h2 {
        font-size: 4em;
        margin-bottom:10px;
    }

    .about-me-section p {
        font-size: 1em;
        line-height: 1.4;
    }

    .slideshow-container {
        height: 350px; 
        margin-top: 20px; 
    }
}

@media (min-width: 768px) and (max-width: 940px) {
    .about-me-section {
        padding-top: 40px;
        overflow-y: hidden;
    }
}

@media (max-width: 480px) {
    .about-me-section {
        max-width: 95%;
        padding: 10px;
        padding-top: 110px;
        z-index: 1;
        top: 0px;
    }

    .about-me-section h2 {
        font-size: 2.8em;
        padding-bottom: 20px;
        margin-top: 0px;
    }

    .about-me-section p {
        font-size: 0.9em;
        padding-bottom: 0px;
        line-height: 1.4;
    }

    .slideshow-container {
        height: 250px; 
        margin-top: 20px;
    }
}
