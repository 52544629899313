
.projects-section {
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #031c31;
  padding: 20px;
  overflow-x: hidden;
}

.projects-section h2 {
  font-size: 8.5em;
  font-family: "Playfair Display", serif;
  margin-bottom: 30px;
  margin-top: -200px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
  opacity: 0;
}

.project-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  width: 100%;
  perspective: 1000px;
}

.project-banner {
  width: 80%;
  max-width: 600px;
  padding: 20px;
  background: #031c31;
  color: white;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
  opacity: 0;
  transform: translateY(20px);
  will-change: transform, opacity;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.project-banner:hover {
  transform: scale(1.05);
  background-color: #2b4d73;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  backdrop-filter: blur(5px);
}

.modal-fullscreen {
  width: 100%;
  height: 90%;
  background: linear-gradient(135deg, #031c31 0%, #0a2b4a 100%);
  color: #e6e6e6;
  padding: 40px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  position: relative;
  font-family: "Playfair Display", serif;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
  max-width: 1750px;
}

.modal-header {
  text-align: left;
  width: 100%;
  margin-bottom: 15px;
  margin-top: -20px;
}

.modal-header h1 {
  font-size: 2.8rem;
  background: linear-gradient(45deg, #91badc, #6fa0c7);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 5px;
}

.project-type {
  color: #91badc;
  font-size: 1.1rem;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.modal-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  flex-wrap: wrap;
  width: 100%;
}

.modal-left {
  flex: 1;
  max-width: 55%;
  text-align: left;
  padding-right: 20px;
}


.modal-right {
  flex: 1;
  max-width: 45%;
  display: flex;
}

.video-container {
  width: 100%;
  max-width: 900px; 
  position: relative;
  padding-bottom: 56.25%; 
  border-radius: 10px;
  background: #000;
  top: -75px;
  z-index: 10;

}

.video-container video {
  pointer-events: auto; 
  z-index: 10; 
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}


.tech-stack {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  
}

.tech-badge {
  background: #2b4d73;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 14px;
  color: white;
}


.features-section {
  display: flex;
  flex-direction: column;
  margin-top: -50px;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 20px; 
  align-items: stretch;


}

.feature-card {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  text-align: center;
  font-size: 0.9rem; 
  min-height: 120px; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  
}


.feature-card h3 {
  font-size: 1rem;
  margin-bottom: 5px;
}

.close-btn {
  font-size: large;
  position: absolute;
  top: 20px;
  right: 30px;
  background: none;
  border: none;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  color: #e6e6e6;
  cursor: pointer;
}

.close-btn:hover {
  transform: rotate(90deg);
}

.feature-number {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 1.5rem;
  opacity: 0.3;
  font-weight: bold;
}

@media (min-width: 1020px) and (max-width: 1540px) {
  .modal-fullscreen {
    overflow-y: auto;
    overflow-x: hidden;
  }

  .video-container {
    top: 0px !important;
  }

  .features-section {
    margin-top: 20px !important;
  }
}


@media (min-width: 768px) and (max-width: 1020px) {
  .modal-content {
    display: grid;
    grid-template-columns: 70% 30%;
    grid-template-rows: auto auto;
    grid-template-areas:
      "video desc-title"
      "tech  desc-title";
    gap: 20px;
    padding-bottom: 40px;
  }

  .modal-fullscreen {
    overflow-y: auto;
    overflow-x: hidden;
  }

  .modal-left {
    display: contents;
  }

  .modal-right {
    display: contents !important;
    max-width: 100% !important;
  }

  .video-container {
    grid-area: video;
    width: 95% !important;
    max-width: 100% !important;
    max-height: auto; 
    position: relative !important;
    top: 0 !important;         
    margin-top: 0 !important;
    padding-bottom: 56.25% !important; 
  }

  .modal-left .section-title {
    grid-area: desc-title;
    margin: 0;
    align-self: start;
  }
  .modal-left .overview {
    grid-area: desc-title; 
    max-width: 100%;
    margin-top: 10px;
    align-self: start;
  }

  .modal-left .tech-stack {
    grid-area: tech;
    margin-top: 10px;
  }
}
@media (min-width: 768px) and (max-width: 940px) {
  .projects-section h2 {
    font-size: 5em;
    padding-top: 100px;
  }
}
@media (max-width: 767px) {
  .projects-section h2 {
    font-size: 3.5em;
    margin-top: -120px;
  }

  .project-banner {
    width: 95%;
  }

  .modal-fullscreen {
    padding: 20px;
    height: 95%;
    overflow-y: auto;
  }

  .modal-header {
    text-align: center;
  }

  .modal-header h1 {
    font-size: 1.8rem;
    margin-top: 20px;
  }

  .modal-content {
    flex-direction: column;
  }

  .modal-left,
  .modal-right {
    max-width: 100%;
    width: 100%;
  }

  .video-container {
    order: 1;
    margin-bottom: 30px;
    top: 0;
  }

  .modal-left {
    order: 2;
    padding-right: 0;
  }

  .tech-stack {
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .features-section {
    order: 3;
    margin-top: 20px;
  }

  .features-grid {
    grid-template-columns: 1fr;
  }

  .project-type {
    font-size: 0.9rem;
  }

  .close-btn {
    top: 15px;
    right: 20px;
  }
}
@media (max-width: 480px) {
  .projects-section h2 {
    font-size: 3em;
  }

  .modal-header h1 {
    font-size: 1.6rem;
  }

  .tech-badge {
    font-size: 10px;
    padding: 6px 10px;
  }

  .feature-card {
    padding: 10px;
  }

  .close-btn {
    top: 10px;
    right: 15px;
  }
}

@media (max-width: 375px) {
  .projects-section h2 {
    font-size: 2.5em;
  }
  
  .modal-header h1 {
    font-size: 1.4rem;
  }
}

