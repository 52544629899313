#contact {
    text-align: center;
    padding: 50px;
    background: #261a23; 
    color: #e6e6e6;
    border-radius: 10px;
    margin: 50px auto;
    max-width: 600px; 
  }
  
  #contact h2 {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #e6e6e6; 
  }
  
  .contact-button {
    display: inline-block;
    padding: 15px 30px;
    font-size: 1.2em;
    color: #261a23; 
    background-color: #a7f2e4;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .contact-button:hover {
    background-color: #3c6e65; 
    color: #261a23; 
  }
  