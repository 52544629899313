
body {
  font-family: "Arial", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: #050505; 
  scroll-behavior: smooth; 
  overflow-y: scroll; 
  position: relative; 
}

.app-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  padding: 0px 20px; 
  box-sizing: border-box;
  flex-wrap: wrap;
  position: relative;
  top: -40px;
  background-color:#c7c7c7;;
}
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  background-color: #c7c7c7;
}
.main-content {
  flex: 1 1 600px; 
  max-width: 900px; 
  background-color:#c7c7c7;;
  overflow-y: auto;
  box-sizing: border-box; 
  margin: 0; 
  scroll-snap-type: y mandatory;
}




@media (max-width: 768px) {
  .app-container {

    align-items: center;
    padding: 25px 0px 0px 0px; 
  }

  .main-content {
    width: 100%;
    max-width: 95%; 
    padding: 10px; 
  }

  .mouse-hover-effect {
    width: 250px; 
    height: 250px;
  }
}





@media (max-width: 480px) {
  .app-container {
    padding: 10px;
  }

  .main-content {
    width: 100%; 
    max-width: 100%; 
    margin: 0 auto; 
  }

  .main-content {
    overflow-y: visible; 
    height: auto; 
  }

  .app-container {
    flex-direction: column;
    overflow-y: visible;
  }

  .mouse-hover-effect {
    display: none;
  }
}


